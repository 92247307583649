import React, { useEffect, useState, useContext } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import icon from "../logo.png";
import styles from "./Main.module.css";
import {
  AppShell,
  Navbar,
  Header,
  Text,
  ScrollArea,
  Avatar,
  Divider,
  NavLink,
} from "@mantine/core";
import { UserButton } from "../components/UserButton/UserButton";
import { IconHome2, IconBook, IconReportMoney } from "@tabler/icons-react";
import GeneratePage from "./Generate";
import AuthContext from "../contexts/AuthContext/AuthContext";
import { getHost } from "../utils";
import { setPriority } from "os";

interface Profile {
  displayName: string;
  email: string;
  displayImage: string;
}

const MainPage = () => {
  const currentPath = window.location.pathname;
  const [profile, setProfile] = useState<Profile | null>(null);
  console.log(currentPath);

  const { accessToken } = useContext(AuthContext);
  console.log("access", accessToken);
  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const profile = await (
          await fetch(getHost() + "/user/profile", {
            method: "GET",
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          })
        ).json();
        console.log("profile", profile);
        setProfile({
          displayImage:
            profile.images &&
            profile.images.length > 0 &&
            profile.images[0].url,
          displayName: profile.display_name,
          email: profile.email,
        });
      } catch (err) {
        console.error(err);
      }
    };

    if (accessToken) {
      fetchProfile();
    }
  }, [accessToken]);

  return (
    <AppShell
      padding="md"
      navbar={
        <Navbar width={{ base: 300 }} p="xs">
          <Navbar.Section mt="xs">
            {/* Header with logo */}
            <div className={styles.header}>
              <img src={icon} alt="logo" width="75px" height="70px" />
              <h1>Vibelist</h1>
            </div>
          </Navbar.Section>

          <Divider my="sm" />

          <Navbar.Section grow component={ScrollArea} mx="-xs" px="xs">
            <NavLink
              active={currentPath.startsWith("/generate")}
              label="Generate"
              onClick={() => {
                window.location.href = "/generate" + window.location.search;
              }}
              icon={<IconHome2 size="2rem" stroke={1.5} />}
            />
            <NavLink
              label="Library"
              icon={<IconBook size="2rem" stroke={1.5} />}
            />
            <NavLink
              label="Get More Credits"
              icon={<IconReportMoney size="2rem" stroke={1.5} />}
            />
          </Navbar.Section>

          <Navbar.Section>
            {profile && (
              <UserButton
                style={{ width: "100%" }}
                image={profile.displayImage}
                name={profile.displayName}
                email={profile.email}
              />
            )}
          </Navbar.Section>
        </Navbar>
      }
    >
      <Routes>
        <Route path="/generate" element={<GeneratePage />} />
        {/*<Route path="/library" element={<div>Login</div>} />*/}
      </Routes>
    </AppShell>
  );
};

export default MainPage;
