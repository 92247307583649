import React, { useEffect, useState, useContext } from "react";
import VibeBox from "../components/VibeBox";
import Playlist from "../components/Playlist";
import MenuBar from "../components/MenuBar";
import { SpotifySong, GenPlaylist } from "../types";
import SpotifyPlayer from "react-spotify-web-playback";
import styles from "./Generate.module.css";
import { getHost } from "../utils";
import SavedList from "../components/SavedList";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import AuthContext from "../contexts/AuthContext/AuthContext";

function App() {
  const [songs, setSongs] = useState<SpotifySong[]>([]);
  const [vibe, setVibe] = useState<string | undefined | null>();
  const [savedPlaylists, setSavedPlaylists] = useState<GenPlaylist[]>([]);
  const [playlistId, setPlaylistId] = useState<number | null>(null);
  const [playOffset, setPlayOffset] = useState(0);
  const [playing, setPlaying] = useState(false);
  const [loading, setLoading] = useState(false);

  const { accessToken } = useContext(AuthContext);

  useEffect(() => {
    if (!vibe && accessToken !== "") {
      // Fetch saved list.
      const params = new URLSearchParams({
        saved: "true",
        limit: "10",
      });

      fetch(getHost() + `/playlists/gen?${params}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res) => {
          if (res.status === 200) {
            return res.json();
          } else {
            throw new Error("Error fetching saved playlists");
          }
        })
        .then((data) => {
          if (data) {
            console.log("saved playlists", data);
            setSavedPlaylists(data);
          } else {
            throw new Error(
              "Error fetching saved playlists: playlist is undefined"
            );
          }
        });
    }
  }, [accessToken, vibe]);

  const generateVibe = async (vibe: string) => {
    setVibe(vibe);
    setSongs([]);
    setPlaying(false);
    setLoading(true);
    const streamResults = async () => {
      const response = await fetchEventSource(
        getHost() + "/generate/prompt/" + encodeURIComponent(vibe),
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          onmessage(msg) {
            console.log("new event", msg);
            const data = JSON.parse(msg.data);
            console.log("data", data, data.type);
            if (data.type === "header") {
              console.log("playlist id", data.playlist_id);
              setPlaylistId(data.playlist_id);
            } else if (data.type === "song") {
              const song = {
                uri: data.uri,
                name: data.name,
                artists: data.artists,
                preview_url: data.preview_url,
                album: data.album,
              } as SpotifySong;
              console.log("new song", song);
              setSongs((songs) => [...songs, song]);
            }
          },
          onclose() {
            console.log("new event", "done");
            setPlaying(true);
            setLoading(false);
          },
        }
      );
    };
    streamResults();
  };

  const playSong = (song: SpotifySong) => {
    const offset = songs.indexOf(song);
    if (offset == -1) {
      console.error("song not found", song);
    }
    setPlayOffset(offset);
  };

  const handleExportPlaylist = () => {
    fetch(getHost() + `/playlists/gen/${playlistId}/export/spotify`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    }).then((res) => {
      if (res.status === 200) {
        console.log("Playlist exported to spoitfy", playlistId);
        return;
      } else {
        console.error("Playlist not exported");
      }
    });
  };

  const handleSavePlaylistToLibrary = () => {
    fetch(getHost() + `/playlists/gen/${playlistId}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        saved: true,
      }),
    }).then((res) => {
      if (res.status === 200) {
        console.log("Playlist saved", playlistId);
        return;
      } else {
        console.error("Playlist not saved");
      }
    });
  };

  useEffect(() => {
    if (!loading && songs && songs.length > 0) {
      playSong(songs[0]);
    }
  }, [songs, loading]);

  const handleLoadSavedPlaylist = (playlist: GenPlaylist) => {
    console.log("picking saved list", playlist);
    setVibe(playlist.prompt);
    setPlaylistId(playlist.id);
    fetch(getHost() + `/playlists/gen/${playlist.id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        } else {
          throw new Error(`Error fetching saved playlist {playlist.id}`);
        }
      })
      .then((data) => {
        if (data) {
          const songs = data.songs;
          if (songs && songs.length > 0) {
            setSongs(songs);
          } else {
            setSongs([]);
          }
        }
      });
  };

  const handleBack = () => {
    setVibe(null);
    setSongs([]);
    setPlaylistId(null);
  };

  return (
    <div className={styles.container}>
      <div className={styles.vibeBox}>
        <VibeBox vibe={vibe} onClick={generateVibe} />
      </div>
      <div className={styles.playlist}>
        <MenuBar
          onBack={loading || songs.length === 0 ? undefined : handleBack}
          onSaveToLibrary={
            loading || songs.length === 0
              ? undefined
              : handleSavePlaylistToLibrary
          }
          onExport={
            loading || songs.length === 0 ? undefined : handleExportPlaylist
          }
        />
        {!vibe ? (
          <SavedList
            playlists={savedPlaylists}
            onClickPlaylist={handleLoadSavedPlaylist}
          />
        ) : (
          <Playlist songs={songs} onClick={playSong} loading={loading} />
        )}
      </div>
      <div className={styles.player}>
        <SpotifyPlayer
          play={playing}
          offset={playOffset}
          token={accessToken}
          uris={songs.map((song) => song.uri)}
          styles={{}}
        />
      </div>
    </div>
  );
}

export default App;
