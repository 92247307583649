import React from "react";

interface AuthContextProps {
  accessToken: string;
  accessTokenExpiresAt: number;
  logout: () => void;
}

const AuthContext = React.createContext<AuthContextProps>({
  accessToken: "",
  accessTokenExpiresAt: 0,
  logout: () => {},
});

export default AuthContext;
