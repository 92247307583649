import { FaSpotify } from "react-icons/fa";

interface IProps {
  uri: string;
}

const PlayOnSpotifyButton = (props: IProps) => {
  const { uri } = props;
  if (uri.split(":").length >= 3) {
    const hashString = uri.split(":")[2];

    return (
      <a href={`https://open.spotify.com/track/${hashString}`} target="_blank">
        <button className="spotify-button">
          <FaSpotify className="spotify-logo" />
          &nbsp; Play on Spotify
        </button>
      </a>
    );
  }
  return <></>;
};

export default PlayOnSpotifyButton;
