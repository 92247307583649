import React, { useEffect, useState } from "react";
import styles from "./VibeBox.module.css";
import SpotifyLogo from "../../spotify.png";

interface IProps {
  vibe?: string | null;
  onClick?: (vibe: string) => void;
}

function VibeBox(props: IProps) {
  const [searchTerm, setSearchTerm] = useState("");

  const handleChange = (event: any) => {
    setSearchTerm(event.target.value);
  };

  const { vibe } = props;
  useEffect(() => {
    if (vibe !== undefined && vibe !== null) {
      setSearchTerm(vibe);
    }
  }, [vibe]);

  const handleClick = () => {
    console.log(`Search term: ${searchTerm}`);
    props.onClick && props.onClick(searchTerm);
  };

  return (
    <div className={styles.container}>
      <input
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleClick();
          }
        }}
        type="text"
        spellCheck="false"
        value={searchTerm}
        onChange={handleChange}
      />
      <button className={styles.button} onClick={handleClick}>
        Generate
      </button>
      {/*<button onClick={handleLogout}>Logout</button>*/}
    </div>
  );
}

export default VibeBox;
