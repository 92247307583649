import React from "react";
import styles from "./Playlist.module.css";
import ReactLoading from "react-loading";

import { SpotifySong } from "../../types";
import PlayOnSpotifyButton from "./PlayOnSpotifyButton";
import MenuBar from "../MenuBar";

const Song = ({
  song,
  onClick,
}: {
  song: SpotifySong;
  onClick: (song: SpotifySong) => void;
}) => (
  <li className={styles.song} onClick={() => onClick(song)}>
    {song.album.images && song.album.images.length > 0 && (
      <img
        className={styles.song_img}
        src={song.album.images[0].url}
        alt="album art"
      />
    )}
    <h3 className={styles.song_title}>{song.name}</h3>
    <p>{song.artists}</p>
    <span className={styles.play_on_spotify}>
      <PlayOnSpotifyButton uri={song.uri} />
    </span>
  </li>
);

const Playlist = ({
  songs,
  onClick,
  loading,
}: {
  songs: SpotifySong[];
  onClick: (song: SpotifySong) => void;
  loading: boolean;
}) => {
  if (songs.length == 0) {
    return (
      <div className={styles.no_results}>
        {loading ? (
          <ReactLoading type="spin" color="#1DB954" height={50} width={50} />
        ) : (
          "Some good vibes coming."
        )}
      </div>
    );
  } else {
    return (
      <div className={styles.container}>
        <ul className={styles.playlist}>
          {songs.map((song) => (
            <Song key={song.name} song={song} onClick={onClick} />
          ))}
        </ul>
      </div>
    );
  }
};

export default Playlist;
