import React from "react";
import { getHost } from "../utils";
import icon from "../logo.png";
import styles from "./Login.module.css";
import mixpanel from "mixpanel-browser";

const LoginPage = () => {
  return (
    <div className={styles.container}>
      <div>
        <div className={styles.header}>
          <img src={icon} alt="logo" width="100px" />
          <h1>Vibelist</h1>
        </div>
        <div className={styles.subheader}>Make music playlists with AI.</div>
      </div>
      <button
        className={styles.button}
        onClick={() => {
          window.location.href = getHost() + "/auth/spotify/login";
          mixpanel.track("Login");
        }}
      >
        Login with Spotify
      </button>
    </div>
  );
};

export default LoginPage;
