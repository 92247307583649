import React, { useState } from "react";
import SpotifyLogo from "../../spotify.png";
import styles from "./MenuBar.module.css";

interface IProps {
  onSaveToLibrary?: () => void;
  onExport?: () => void;
  onBack?: () => void;
}

function MenuBar(props: IProps) {
  return (
    <div className={styles.container}>
      <img className={styles.spotify_logo} src={SpotifyLogo} />

      <p>
        {props.onBack && (
          <span onClick={props.onBack} className={styles.back}>
            Back
          </span>
        )}
        {props.onSaveToLibrary && (
          <span
            onClick={props.onSaveToLibrary}
            className={styles.save_to_library}
          >
            Save to Library
          </span>
        )}
        {props.onExport && (
          <span
            onClick={props.onExport}
            className={styles.export_to_library}
          >
            Export to Spotify
          </span>
        )}
      </p>
    </div>
  );
}

export default MenuBar;
