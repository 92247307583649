import React, { useEffect } from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LoginPage from "./pages/Login";
import MainPage from "./pages/Main";
import mixpanel from "mixpanel-browser";
import AuthProvider from "./contexts/AuthContext/AuthProvider";

const App: React.FC = () => {
  // TODO: move this to a better place.
  useEffect(() => {
    console.log("App mounted. Initializing MixPanel...");
    mixpanel.init("a09fb68b425dfe5c34a373062ebf26e9", { debug: true });

    // Track an event. It can be anything, but in this example, we're tracking a Signed Up event.
    // Include a property about the signup, like the Signup Type
    mixpanel.track("Signed Up", {
      "Signup Type": "Referral",
    });
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route
          path="/*"
          element={
            <AuthProvider>
              <MainPage />
            </AuthProvider>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
