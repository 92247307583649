import React, { useState, useEffect } from "react";
import { getHost } from "../../utils";
import queryString from "query-string";
import AuthContext from "./AuthContext";

const AuthProvider = (props: { children: any }) => {
  const [accessToken, setAccessToken] = useState("");
  const [accessTokenExpiresAt, setAccessTokenExpiresAt] = useState(0);

  const refreshToken = async (token: string, expires_at: number) => {
    console.log(
      "Refreshing tokens",
      "Expires at: ",
      new Date(expires_at * 1000),
      "Now at",
      Date.now()
    );
    const response = await fetch(`${getHost()}/auth/spotify/refresh_token`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 200) {
      const data = await response.json();
      setAccessToken(data.access_token);
      setAccessTokenExpiresAt(data.expires_at);
      console.log(
        "Refreshing tokens in",
        (data.expires_at * 1000 - Date.now()) / 2,
        "seconds"
      );
      window.setTimeout(
        () => refreshToken(token as string, data.expires_at),
        (data.expires_at * 1000 - Date.now()) / 2
      );
    } else {
      console.log("refresh token failed", response.status);
    }
  };

  const logout = () => {
    setAccessToken("");
    window.location.href = "/login";
  };

  useEffect(() => {
    const checkIfLoggedIn = async () => {
      const parsed = queryString.parse(window.location.search);
      const token = parsed.access_token;
      const expiresAt = parsed.expires_at;

      if (!expiresAt) {
        window.location.href = "/login";
        return;
      } else {
        const dateExpires = new Date(Number(expiresAt) * 1000);
        const dateNow = new Date();
        console.log("dateExpires", dateExpires, "dateNow", dateNow);
        if (dateExpires <= dateNow) {
          // token expired.
          refreshToken(token as string, Number(expiresAt)).catch((err) => {
            console.error(err);
            window.location.href = "/login";
          });
        } else {
          window.setTimeout(
            () => refreshToken(token as string, Number(expiresAt)),
            (dateExpires.getTime() - dateNow.getTime()) / 2
          );
        }
      }

      setAccessTokenExpiresAt(Number(expiresAt));
      console.log("token expires at", expiresAt);

      if (token) {
        console.log("logged in");
        setAccessToken(token as string);
        const res = await fetch(getHost() + "/auth/spotify/valid", {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (res.status !== 200) {
          console.log("not logged in");
          window.location.href = "/login";
          return;
        }
      } else {
        window.location.href = "/login";
      }
    };

    checkIfLoggedIn();
  }, []);

  return (
    <AuthContext.Provider value={{ accessToken, accessTokenExpiresAt, logout }}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
