import React from "react";
import styles from "./SavedList.module.css";
import { GenPlaylist } from "../../types";

interface IProps {
  playlists: GenPlaylist[];
  onClickPlaylist?: (playlist: GenPlaylist) => void;
}

const SavedList = (props: IProps) => {
  const { playlists, onClickPlaylist } = props;
  return (
    <div className={styles.container}>
      <ul className={styles.playlist}>
        {playlists.map((playlist) => (
          <li
            key={"playlist-" + playlist.id}
            className={styles["playlist-item"]}
            onClick={() => onClickPlaylist && onClickPlaylist(playlist)}
          >
            <h2>{playlist.prompt}</h2>
            <p>10 songs</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SavedList;
